import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Instagram: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className }) => {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 30 30"
        fill="#fff"
        className={className}
      >
        <path d="M29.924 8.82c-.07-1.594-.328-2.69-.698-3.64a7.322 7.322 0 00-1.735-2.66A7.385 7.385 0 0024.836.791c-.955-.37-2.046-.627-3.64-.697C19.59.018 19.08 0 15.006 0s-4.584.018-6.184.088c-1.595.07-2.69.328-3.64.697A7.321 7.321 0 002.52 2.52 7.386 7.386 0 00.79 5.175C.422 6.13.164 7.22.094 8.815.018 10.42 0 10.93 0 15.002c0 4.073.018 4.583.088 6.183.07 1.594.328 2.69.698 3.64a7.398 7.398 0 001.735 2.66 7.385 7.385 0 002.655 1.729c.955.369 2.046.627 3.64.697 1.6.07 2.11.088 6.184.088s4.584-.017 6.184-.088c1.595-.07 2.69-.328 3.64-.697a7.674 7.674 0 004.39-4.39c.37-.955.628-2.045.698-3.64.07-1.6.088-2.109.088-6.182s-.006-4.583-.076-6.183zM27.222 21.07c-.065 1.465-.311 2.256-.516 2.783a4.977 4.977 0 01-2.85 2.849c-.527.205-1.324.45-2.783.515-1.583.07-2.058.088-6.061.088-4.004 0-4.484-.017-6.061-.088-1.466-.064-2.257-.31-2.785-.515a4.617 4.617 0 01-1.723-1.12 4.663 4.663 0 01-1.12-1.723c-.205-.527-.45-1.324-.515-2.783-.07-1.583-.088-2.058-.088-6.06 0-4.003.017-4.484.088-6.06.064-1.465.31-2.256.516-2.784a4.56 4.56 0 011.125-1.723 4.658 4.658 0 011.723-1.12c.528-.204 1.325-.45 2.785-.515 1.582-.07 2.057-.088 6.06-.088 4.01 0 4.485.018 6.062.088 1.465.065 2.256.31 2.784.516.65.24 1.242.62 1.723 1.12.498.486.88 1.072 1.12 1.722.205.528.451 1.325.516 2.784.07 1.582.088 2.057.088 6.06 0 4.002-.018 4.471-.088 6.054z" />
        <path d="M14.947 7.296a7.71 7.71 0 00-7.708 7.707 7.71 7.71 0 0015.416 0 7.71 7.71 0 00-7.708-7.707zm0 12.706a5 5 0 01-5-4.999 5 5 0 0110 0 5 5 0 01-5 5zM24.759 6.992a1.8 1.8 0 11-3.599 0 1.8 1.8 0 013.599 0z" />
      </svg>
    );
  }
);
