import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Linkedin: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className }) => {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 32 31"
        fill="#fff"
        className={className}
      >
        <path d="M4.378.182C2.077.182.573 1.692.573 3.678c0 1.942 1.46 3.496 3.716 3.496h.044c2.345 0 3.805-1.554 3.805-3.496C8.094 1.693 6.678.182 4.378.182zM.77 9.948h6.726v20.233H.77V9.948zM24.003 9.474c-3.628 0-6.06 3.409-6.06 3.409V9.949h-6.726v20.233h6.725v-11.3c0-.604.044-1.208.221-1.64.486-1.209 1.593-2.46 3.45-2.46 2.434 0 3.408 1.856 3.408 4.576v10.824h6.724V18.58c0-6.214-3.318-9.106-7.742-9.106z" />
      </svg>
    );
  }
);
