import React, { FC } from 'react';
import styled from 'styled-components';

import { Facebook, Instagram, Linkedin, Skype, Watsup } from './icons';
import { Link } from './link';
import { LogoSVG } from './logo-svg';

const Wrapper = styled.footer`
  background-color: ${p => p.theme.colors.dark};
  height: 20em;
  ${p => p.theme.maxWidth.phone`
    height: auto;
  `}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 3em;
  ${p => p.theme.maxWidth.phone`
    flex-direction: column-reverse;
  `}
`;

const Copy = styled.div`
  width: auto;
  margin-top: auto;
  ${p => p.theme.maxWidth.phone`
    margin: auto;
    text-align: center;
    font-size: 1.2em;
  `}

  > a {
    width: 8.3125em;
    height: 1em;

    > svg {
      width: 8.3125em;
      height: 1em;
      transition: fill 0.2s ease-in-out;
      fill: ${p => p.theme.colors.white};
      ${p => p.theme.maxWidth.phone`
        font-size: 1.2em;
      `}
    }

    &:hover {
      > svg {
        fill: ${p => p.theme.colors.red};
      }
    }
  }

  > p {
    font-size: 1.125em;
    font-weight: 800;
    color: ${p => p.theme.colors.white};
  }
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: auto;
  ${p => p.theme.maxWidth.phone`
    margin-bottom: 4em;
    font-size: 1.2em;
  `}

  > a {
    width: 2em;
    height: 2em;
    margin-right: 1em;

    &:last-of-type {
      margin-right: 0;
    }

    > svg {
      width: 2em;
      height: 2em;
      transition: fill 0.2s ease-in-out;
    }

    &:hover {
      > svg {
        fill: ${p => p.theme.colors.red};
      }
    }
  }
`;

const FacebookLink = styled(Link)``;
const InstagramLink = styled(Link)``;
const LinkedinLink = styled(Link)``;

const Anchors = styled.div`
  display: flex;
  flex-direction: column;
  ${p => p.theme.maxWidth.phone`
    margin-bottom: 4em;
  `}

  > a {
    font-size: 2.5em;
    font-weight: 800;
    text-transform: capitalize;
    width: fit-content;
    margin-left: auto;
    color: ${p => p.theme.colors.white};
    transition: color 0.2s ease-in-out;
    ${p => p.theme.maxWidth.phone`
      margin: auto;
    `}

    &:hover {
      color: ${p => p.theme.colors.red};
    }
  }
`;

const data = {
  facebook: `https://web.facebook.com/PhilipandMax/?_rdc=1&_rdr`,
  linkedin: `https://www.linkedin.com/company/philip-and-max/`,
  instagram: `https://www.instagram.com/philipandmax_agency/`,
  skype: `https://www.google.com`,
  watsup: `https://www.goolge.com`,
};

export const anchorData = [
  {
    id: `#services`,
    label: `Services`,
  },
  {
    id: `#projects`,
    label: `Projects`,
  },
  {
    id: `#about`,
    label: `We are`,
  },
  {
    id: `#team`,
    label: `Task force`,
  },
];

export const Footer: FC = () => {
  return (
    <Wrapper>
      <Container>
        <Copy>
          <Link to="/">
            <LogoSVG />
          </Link>
          <p>2021</p>
        </Copy>
        <Social>
          <FacebookLink to={data.facebook}>
            <Facebook />
          </FacebookLink>
          <InstagramLink to={data.instagram}>
            <Instagram />
          </InstagramLink>
          <LinkedinLink to={data.linkedin}>
            <Linkedin />
          </LinkedinLink>
        </Social>
        <Anchors>
          {anchorData.map(el => (
            <Link key={el.id} to={el.id}>
              {el.label}
            </Link>
          ))}
        </Anchors>
      </Container>
    </Wrapper>
  );
};
