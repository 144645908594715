import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Phone: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '' }) => {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 67 66"
        fill="none"
        className={className}
      >
        <path
          d="M33.5 63C50.293 63 64 49.62 64 33 64 16.38 50.293 3 33.5 3S3 16.38 3 33c0 16.62 13.707 30 30.5 30z"
          stroke="#1F1F25"
          strokeWidth={6}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.386 47.703c-.563-.862-3.35-5.064-3.926-5.756-.575-.69-1.03-1.134-2.041-.659-1.011.475-3.62 1.35-4.405 1.602-.786.252-1.293.045-1.856-.817-.563-.861-2.642-3.2-4.08-7.666-1.12-3.476-1.168-6.99-1.159-8.05.01-1.06.706-1.175 1.324-1.4 1.508-.55 3.048-1.152 3.633-1.52.585-.369.73-.906.767-1.417.038-.51.78-5.317 1.067-7.281.277-1.913-.508-2.092-.986-2.403a37.258 37.258 0 00-1.52-.924c-.534-.312-1.52-.623-2.733-.264-1.21.359-4.433 1.052-6.777 4.923-2.345 3.87-1.738 9.286-1.656 10.045.083.759.522 11.767 6.498 19.867a52.613 52.613 0 003.565 4.324c1.557 1.72 3.355 2.655 4.858 3.259 1.677.673 5.893.865 7.674-.581 1.78-1.447 2.734-3.022 2.732-3.467 0-.445-.416-.953-.979-1.815z"
          fill="#1F1F25"
        />
      </svg>
    );
  }
);
