import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const Facebook: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className }) => {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 32 31"
        fill="#fff"
        className={className}
      >
        <path d="M29.507.167H1.864C.918.167.15.908.151 1.823v26.689c0 .914.768 1.655 1.715 1.654h14.882V18.566h-4.035v-4.541h4.035v-3.342c0-3.875 2.45-5.985 6.031-5.985 1.715 0 3.189.124 3.619.179v4.05h-2.47c-1.948 0-2.325.894-2.325 2.206v2.892h4.658l-.607 4.54h-4.05v11.602h7.903c.947 0 1.715-.74 1.715-1.655V1.82c0-.914-.768-1.655-1.715-1.654z" />
      </svg>
    );
  }
);
