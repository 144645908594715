const base = {
  name: `Philip & Max`, // Site title.
  shortName: `Philip&Max`, // Site title.
  url: `https://philipandmax.com`, // Domain of your website. No trailing slash!
};

module.exports = {
  /* meta tags */
  siteTitle: `${base.name}`, // Manifest `name`
  siteTitleAlt: `${base.name}`, // Title for JSONLD.
  siteShortName: base.shortName, // Manifest `short_name`. MUST be shorter than 12 characters
  siteTitleTemplate: `%s | ${base.name}`, // Useful when you want titles to inherit from a template
  siteDescription: `Welcome to Philip and Max your all in one digital marketing partner. One stop shop for Web & Graphic Design, Google and Social Media marketing, SEO and much more.`, // Manifest `description`

  /* url */
  siteUrl: base.url, // Domain of your site. No trailing slash!
  pathPrefix: `/`, // Prefixes all links. For cases when deployed to example.github.io/gatsby-example/.

  /* social */
  siteFeaturedImage: `/featured-image.jpg`, // Logo used for SEO.
  siteFeaturedImageAlt: `Welcome to Philip and Max your all in one digital marketing partner. One stop shop for Web & Graphic Design, Google and Social Media marketing, SEO and much more.`, // Logo description used for SEO.

  googleAnalyticsID: `G-EBMBBP9EGF`, // googleAnalyticsID gtag tracking ID.

  fbAppID: ``, // FB Application ID for using app insights
  fbPixelID: `277669843921456`, // FB Pixel ID

  /* manifest */
  manifestBackgroundColor: `#fff`, // Used for setting manifest background and progress theme colors.
  manifestThemeColor: `#1e3e5e`, // Used for setting manifest theme color .
  manifestDisplay: `standalone`,
  manifestIcon: `static/pm-icon.png`, // This path is relative to the root of the site.
};
