import React, { FC } from 'react';
import styled from 'styled-components';

import { Footer } from '~src/components/footer';
import { SEO } from '~src/components/seo';

const Wrapper = styled.div``;

type Props = {
  pathname?: string;
  seoData: {
    title?: string;
    description?: string;
    featured_image?: {
      imageFile: {
        childImageSharp: {
          fixed: {
            src: string;
          };
        };
      };
    };
  };
};

const PageLayout: FC<Props> = ({ children, seoData, pathname }) => {
  return (
    <>
      <SEO seoData={seoData} pathname={pathname} />
      <Wrapper>{children}</Wrapper>
      <Footer />
    </>
  );
};

export default PageLayout;
