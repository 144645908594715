import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import i18n from '~config/i18n';
import { useSiteMetadata } from '~src/hooks';

type Props = {
  lang?: keyof typeof i18n;
  pathname?: string;
  seoData: {
    title?: string;
    description?: string;
    featuredImage?: string;
    featuredImageAlt?: string;
  };
};

export const SEO: FC<Props> = ({ lang = 'en', pathname = '', seoData }) => {
  const {
    siteTitle,
    siteTitleAlt,
    siteTitleTemplate,
    siteUrl,
    siteFeaturedImage,
    siteFeaturedImageAlt,
    siteDescription,
    fbAppID,
  } = useSiteMetadata();

  const { htmlLang, ogLang } = i18n[lang];

  const seo = {
    title: seoData.title || siteTitle,
    description: seoData.description || siteDescription,
    featuredImage: `${siteUrl}${seoData.featuredImage || siteFeaturedImage}`,
    featuredImageAlt: seoData.featuredImageAlt || siteFeaturedImageAlt,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      title={seo.title}
      defaultTitle={siteTitleAlt}
      titleTemplate={siteTitleTemplate}
    >
      <html lang={htmlLang} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.featuredImage} />
      <meta property="og:locale" content={ogLang} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.featuredImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />
      <meta property="og:image:alt" content={seo.featuredImageAlt} />
      <meta property="fb:app_id" content={fbAppID} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.featuredImage} />
      <meta name="twitter:image:alt" content={seo.featuredImageAlt} />
    </Helmet>
  );
};
